import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';
import { StoryEntity } from 'types';

import apiClient, { USER_ID } from 'lib/apiClient';

const getStory = async () => {
  const response = await apiClient.get<StoryEntity>(`/story_request/${USER_ID}`);
  return camelCaseKeys(response.data, { deep: true });
};

const useGetStory = () => useQuery(['story'], getStory);

export type StoryResponse = CamelCaseKeys<StoryEntity, true>;
export default useGetStory;
