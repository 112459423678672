import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import renderSwitch from 'lib/renderSwitch';
import { getAudioSrcFromBase64 } from 'lib/utils';

import useGetLesson, { LessonResponse } from 'api/useGetLesson';

import Audio, { AudioProps, AudioRef } from 'components/ui/Audio';
import Card from 'components/ui/Card/Card';
import ContentLesson from 'components/ui/ContentLesson';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';
import Instruction from 'components/Instruction';

import * as Styled from './styles';

type LessonWordsContentProps = Pick<LessonResponse, 'lessonPracticeWords'>;

const LessonWordsContent = ({ lessonPracticeWords }: LessonWordsContentProps) => {
  const [currentWord, setCurrentWord] = useState<string | null>(null);
  const [isAudioPlaying, setIsAudionPlaying] = useState(false);
  const audioRef = useRef<AudioRef>(null);
  const instructionAudioRef = useRef<AudioRef>(null);
  const navigate = useNavigate();

  const playWord = (audio: string, text: string) => {
    instructionAudioRef.current?.stop();
    setCurrentWord(text);
    audioRef.current?.setSrc(getAudioSrcFromBase64(audio));
  };

  const handleAudioStateUpdate: AudioProps['onStateUpdate'] = (state) => {
    state.playing !== isAudioPlaying && setIsAudionPlaying(state.playing);
  };

  return (
    <ContentLesson
      instruction={
        <Instruction
          audioRef={instructionAudioRef}
          text="Click the box to hear the word"
          audio="lesson_instruction/l2.mp3"
        />
      }
      onBack={() => navigate(URLS.lessonSound)}
      onForward={() => navigate(URLS.lessonSentence)}
    >
      <Styled.Wrapper>
        <Audio localSrc="" ignoreAppMute ref={audioRef} onStateUpdate={handleAudioStateUpdate} />
        {lessonPracticeWords.map(({ text, audio }) => (
          <Card
            key={text}
            onClick={() => playWord(audio, text)}
            className={clsx('LessonWords__Card', { active: text === currentWord && isAudioPlaying })}
          >
            <Text align="center" variant="lg">
              {text}
            </Text>
          </Card>
        ))}
      </Styled.Wrapper>
    </ContentLesson>
  );
};

const LessonWords = () => {
  const { data, isFetching, isError, refetch } = useGetLesson();

  const renderState = renderSwitch({
    loading: {
      condition: isFetching,
      render: <Loader />,
    },
    error: {
      condition: isError,
      render: (
        <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this lesson" />
      ),
    },
    default: {
      condition: !!data,
      render: <LessonWordsContent lessonPracticeWords={data?.lessonPracticeWords ?? []} />,
    },
  });

  return <>{renderState}</>;
};

export default LessonWords;
