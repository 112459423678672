import axios from 'axios';

// TODO: hardcoded user_id for MVP
export const USER_ID = '6438285de9c5cbc65bce1685';
export const TREE_ID = '643ce303e9118d7492faa82a';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default apiClient;

export const apiClientSoundOutWord = axios.create({
  baseURL: process.env.REACT_APP_API_URL_SOUND_OUT_WORD,
  responseType: 'blob',
});
