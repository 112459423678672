import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';
import { LessonEntity } from 'types';

import apiClient, { USER_ID } from 'lib/apiClient';

const getLesson = async () => {
  const res = await apiClient.get<LessonEntity>(`/lesson_request/${USER_ID}`);
  return camelCaseKeys(res.data, { deep: true });
};

// caching lesson response data for 50 minutes
const useGetLesson = () => useQuery(['getLesson'], getLesson, { staleTime: 1000 * 60 * 50 });

export type LessonResponse = CamelCaseKeys<LessonEntity, true>;
export default useGetLesson;
