import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import renderSwitch from 'lib/renderSwitch';
import { getAudioSrcFromBase64, getWordsFromText } from 'lib/utils';

import useGetLesson, { LessonResponse } from 'api/useGetLesson';

import useUserLSState from 'hooks/useUserLSState';

import ContentLesson from 'components/ui/ContentLesson';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader';
import Sentence from 'components/ui/Sentence';
import SoundCard from 'components/ui/SoundCard';
import Text from 'components/ui/Text';
import Word from 'components/ui/Word';

import { URLS } from 'components/App';
import Instruction from 'components/Instruction';

import * as Styled from './styles';

type LessonWordsSelectContentProps = Pick<LessonResponse, 'lessonAnswerKeys' | 'letters' | 'sound' | 'lessonStory'>;

const LessonWordsSelectContent = ({ lessonAnswerKeys, letters, sound, lessonStory }: LessonWordsSelectContentProps) => {
  const navigate = useNavigate();
  const { updateState, userState } = useUserLSState();

  const toggle = (wordId: string) => {
    const selectedWordsIndexes = userState?.selectedWordsIndexes;

    if (!selectedWordsIndexes) return;

    updateState(
      'selectedWordsIndexes',
      selectedWordsIndexes.includes(wordId)
        ? selectedWordsIndexes.filter((index) => index !== wordId)
        : selectedWordsIndexes.concat(wordId)
    );
  };

  const wordsWithSoundIndexes = lessonAnswerKeys.map((item) => `${item[0]}_${item[1]}`);

  return (
    <ContentLesson
      instruction={
        <Instruction
          text="Now click on  the words with a sound"
          audio="lesson_instruction/l4.mp3"
          additionalComponent={<SoundCard sound={letters} />}
          additionalAudio={getAudioSrcFromBase64(sound)}
        />
      }
      onBack={() => navigate(URLS.lessonSentence)}
      onForward={!!userState?.selectedWordsIndexes?.length ? () => navigate(URLS.lessonResult) : undefined}
    >
      <Styled.Card>
        <Sentence>
          {lessonStory.map(({ text }, lessonStoryIdx) => {
            const words = getWordsFromText(text);

            return words.map((word, idx) => {
              const wordId = `${lessonStoryIdx}_${idx}`;
              const wordHasSound = wordsWithSoundIndexes.includes(wordId);

              return (
                <Word
                  key={wordId}
                  onClick={wordHasSound ? () => toggle(wordId) : undefined}
                  className={
                    wordHasSound
                      ? clsx('LessonWordsSelect__Word', {
                          selected: !!userState?.selectedWordsIndexes?.includes(wordId),
                        })
                      : undefined
                  }
                  withTooltip={!wordHasSound}
                  tooltipProps={
                    wordHasSound
                      ? undefined
                      : {
                          overlay: (
                            <Text align="center" variant="sm" style={{ userSelect: 'none' }} colorType="light">
                              This word doesn't have the letter-sound pair we practiced
                            </Text>
                          ),
                          overlayInnerStyle: {
                            padding: '10px 20px',
                            maxWidth: 500,
                          },
                        }
                  }
                >
                  {word}
                </Word>
              );
            });
          })}
        </Sentence>
      </Styled.Card>
    </ContentLesson>
  );
};

const LessonWordsSelect = () => {
  const { data, isFetching, isError, refetch } = useGetLesson();

  const renderState = renderSwitch({
    loading: {
      condition: isFetching,
      render: <Loader />,
    },
    error: {
      condition: isError,
      render: (
        <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this lesson" />
      ),
    },
    default: {
      condition: !!data,
      render: (
        <LessonWordsSelectContent
          lessonAnswerKeys={data?.lessonAnswerKeys ?? []}
          letters={data?.letters ?? ''}
          sound={data?.sound ?? ''}
          lessonStory={data?.lessonStory ?? []}
        />
      ),
    },
  });

  return <>{renderState}</>;
};

export default LessonWordsSelect;
