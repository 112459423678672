import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import EnterName from 'screens/EnterName';
import LessonFinish from 'screens/LessonFinish';
import LessonResult from 'screens/LessonResult';
import LessonSentence from 'screens/LessonSentence';
import LessonSound from 'screens/LessonSound';
import LessonWords from 'screens/LessonWords';
import LessonWordsSelect from 'screens/LessonWordsSelect';
import Menu from 'screens/Menu';
import Questions from 'screens/Questions';
import SelectAgeRange from 'screens/SelectAgeRange';
import Story from 'screens/Story';
import StoryFinish from 'screens/StoryFinish';

import Layout from 'components/ui/Layout';

import ContentPlug from 'components/ContentPlug';

/**
 * Parent route: /assignment
 *    /enter-name
 *    /select-age-range
 *    /questions
 *    /menu
 *    /story
 *    /story/finish
 *    /lesson/sound
 *    /lesson/words
 *    /lesson/sentence
 *    /lesson/words-select
 *    /lesson/result
 *    /lesson/finish
 */

export const URLS = {
  enterName: '/assignment/enter-name',
  selectAgeRange: '/assignment/select-age-range',
  questions: '/assignment/questions',
  menu: '/assignment/menu',
  story: '/assignment/story',
  storyFinish: '/assignment/story/finish',
  lessonSound: '/assignment/lesson/sound',
  lessonWords: '/assignment/lesson/words',
  lessonSentence: '/assignment/lesson/sentence',
  lessonWordsSelect: '/assignment/lesson/words-select',
  lessonResult: '/assignment/lesson/result',
  lessonFinish: '/assignment/lesson/finish',
};

const AppContent = () => {
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route index path="/" element={<Navigate to={URLS.enterName} replace />} />
          <Route path="/assignment" element={<Outlet />}>
            <Route path="enter-name" element={<EnterName />} />
            <Route path="select-age-range" element={<SelectAgeRange />} />
            <Route path="questions" element={<Questions />} />
            <Route path="menu" element={<Menu />} />
            {/* Story */}
            <Route path="story" element={<Story />} />
            <Route path="story/finish" element={<StoryFinish />} />
            {/* Lesson */}
            <Route path="lesson/sound" element={<LessonSound />} />
            <Route path="lesson/words" element={<LessonWords />} />
            <Route path="lesson/sentence" element={<LessonSentence />} />
            <Route path="lesson/words-select" element={<LessonWordsSelect />} />
            <Route path="lesson/result" element={<LessonResult />} />
            <Route path="lesson/finish" element={<LessonFinish />} />
          </Route>
          <Route path="*" element={<Navigate to={URLS.enterName} replace />} />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPlugVisible, setIsPlugVisible] = useState(true);

  const handleClick = () => {
    setIsPlugVisible(false);
  };

  useEffect(() => {
    // redirect from "/", "/assignment" and "/assignment/" to "/assignment/enter-name"
    if (['/', '/assignment', '/assignment/'].includes(location.pathname)) navigate('/assignment/enter-name');
  }, [navigate, location.pathname]);

  return (
    <Layout>
      {/*
        ContentPlug is used to overcome chrome autoplay policy.
        More info: https://developer.chrome.com/blog/autoplay/
      */}
      <ContentPlug isVisible={isPlugVisible} onClick={handleClick} />
      {!isPlugVisible && <AppContent />}
    </Layout>
  );
};

export default App;
