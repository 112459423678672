import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean, useUnmount } from 'react-use';

import { createURLfromChunks } from 'lib/mediaRecorder';

import useUserLSState from 'hooks/useUserLSState';

import useRecordingStore from 'screens/Story/useRecordingStore';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed';

import { AGAIN_ICON } from 'assets/icons';

import * as Styled from './styles';
import { useSequenceAudio } from './useSequenceAudio';

const PHRASES = [
  {
    id: 0,
    text: 'Congratulations! You finished the story!',
    audio: 'story_instruction/s2.mp3',
  },
  {
    id: 1,
    text: 'Now let’s learn a lesson.',
    audio: 'story_instruction/s3.mp3',
  },
];

const HearMySelf = ({ source }: { source: string[] }) => {
  // TODO: We are not able to get a progress in the future with this approach. Consider creating a BE endpoint that generates preview for us (Serverless function?)
  const sequenceAudio = useSequenceAudio(source);

  useUnmount(() => sequenceAudio.stop());

  return (
    <Styled.StoryFinishRecordingControl
      onClick={async () => await sequenceAudio.toggle()}
      status={sequenceAudio.playbackStatus}
      text="Hear myself"
    />
  );
};

const StoryFinish = () => {
  const navigate = useNavigate();
  const { updateState } = useUserLSState();
  const [isFeedFull, setIsFeedFull] = useBoolean(false);
  const { recordings, reset: clearRecordings } = useRecordingStore();

  const hasRecordings = useMemo(() => Object.keys(recordings).length > 0, [recordings]);

  const audioSrcSet = useMemo(
    () =>
      Object.values(recordings ?? {}).map(
        (rec) => createURLfromChunks(rec?.chunks ?? [], { type: rec?.chunks?.[0]?.type ?? 'audio/wav' })[0]
      ),
    [recordings]
  );

  const readAgain = () => {
    clearRecordings();
    navigate(URLS.story);
  };

  const goToMenu = async () => {
    // TODO: implement when BE will be defined
    if (!hasRecordings) return;

    // const base64audioPromises = Object.entries(recordings).map(async ([sentence, sentenceState]) => {
    //   const wavBlob = sentenceState?.blob ? new Blob([sentenceState.blob], { type: 'audio/wav' }) : null; // Manually convert to ".wav" as BE requests
    //   return {
    //     sentence: +sentence,
    //     audio: wavBlob ? ((await audioToBase64(wavBlob)) as string) : '',
    //   };
    // });

    // const audios = await Promise.all(base64audioPromises);

    updateState('menu', { story: 'completed', lesson: 'ready' });
    navigate(URLS.menu);
    clearRecordings();
  };

  const handleFeedFull = () => setIsFeedFull(true);

  return (
    <ContentLuca
      backButton={
        <Button onClick={readAgain} iconLeft={AGAIN_ICON}>
          Read again
        </Button>
      }
      onForward={isFeedFull && hasRecordings ? goToMenu : undefined}
    >
      <Feed phrases={PHRASES} onFeedFull={handleFeedFull}>
        {audioSrcSet.length > 0 && <HearMySelf source={audioSrcSet} />}
      </Feed>
    </ContentLuca>
  );
};

export default StoryFinish;
