import { useLocalStorage, useMount } from 'react-use';

import { MenuItemStatuses } from 'types';

type UserState = {
  name: string;
  ageRange: string;
  places: string[];
  characters: string[];
  menu: {
    story: MenuItemStatuses;
    lesson: MenuItemStatuses;
  };
  selectedWordsIndexes: string[];
};

export const initialState: UserState = {
  name: '',
  ageRange: '',
  places: [],
  characters: [],
  menu: {
    story: 'ready',
    lesson: 'locked',
  },
  selectedWordsIndexes: [],
};

const useUserLSState = () => {
  const [userState, setUserState, remove] = useLocalStorage<UserState>('luca:userState', initialState);

  useMount(() => {
    setUserState({ ...initialState, ...userState });
  });

  const updateState = <TKey extends keyof UserState>(key: TKey, value: UserState[TKey]) =>
    setUserState({ ...userState, [key]: value } as UserState);

  return { userState, updateState, remove } as const;
};

export default useUserLSState;
