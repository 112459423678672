import clsx from 'clsx';
import { memo, useEffect, useRef, useState } from 'react';

import { guessPlaybackTime } from 'lib/utils';

import { HighlightsEntity, SoundOutWordHighlightEntity } from 'types';

import useSoundOutWord from 'api/useSoundOutWord';

import type { AudioRef } from 'components/ui/Audio';
import Audio, { AudioProps } from 'components/ui/Audio';
import Loader from 'components/ui/Loader';
import Text from 'components/ui/Text';
import Tooltip from 'components/ui/Tooltip';
import { TooltipProps } from 'components/ui/Tooltip';

import * as Styled from './styles';
import useWordsStore from './useWordsStore';

type TooltipOverlayProps = {
  letters: string[];
  isLoading: boolean;
  audio: string;
  highlights: HighlightsEntity[];
};

const TooltipOverlay = ({ letters, isLoading, audio, highlights }: TooltipOverlayProps) => {
  const audioRef = useRef<AudioRef>(null);
  const [highlightedIndices, setHighlightedIndices] = useState<Array<number>>([]);

  const handleAudioStateUpdate: AudioProps['onStateUpdate'] = (state, audioEl) => {
    if (highlightedIndices.length && state.paused) {
      setHighlightedIndices([]);
      return;
    }

    const currentTime = guessPlaybackTime(state, audioEl);

    const highlight = highlights.find(({ start, end }) => start <= currentTime && end >= currentTime);

    setHighlightedIndices(highlight ? highlight.indices : []);
  };

  useEffect(() => {
    audio && audioRef.current?.play();
  }, [audio]);

  return (
    <Text className="tooltip-overlay" style={{ userSelect: 'none', letterSpacing: 6 }} colorType="light" as="span">
      {isLoading ? (
        <Loader style={{ height: '100%', backgroundColor: 'var(--purple-700)' }} />
      ) : (
        !!audio && (
          <Audio ref={audioRef} src={audio} autoPlay={false} ignoreAppMute onStateUpdate={handleAudioStateUpdate} />
        )
      )}
      {letters.map((letter, idx) => (
        <Text
          className="tooltip-overlay-letter"
          key={`${letter}_${idx}`}
          colorType="light"
          as="span"
          style={{
            ...(highlightedIndices.length && !highlightedIndices.includes(idx)
              ? {
                  color: 'var(--white-transparent-50)',
                }
              : {}),
          }}
        >
          {letter}
        </Text>
      ))}
    </Text>
  );
};

type WordProps = {
  withTooltip?: boolean;
  tooltipContent?: boolean;
  isHighlighted?: boolean;
  onClick?: () => void;
  className?: string;
  tooltipProps?: TooltipProps;
  withSound?: boolean;
  children: string;
};

const Word = ({
  withTooltip = false,
  isHighlighted = false,
  onClick,
  className,
  tooltipProps,
  withSound = false,
  children,
}: WordProps) => {
  const { mutate, isLoading } = useSoundOutWord();

  const wordWithoutSigns = children.replace(/[.,!?]$/, '').toLowerCase();
  const letters = wordWithoutSigns.split('');

  const addWord = useWordsStore((state) => state.addWord);
  const storedWord = useWordsStore((state) => state.storedWords.find(({ name }) => name === wordWithoutSigns));

  const [audio, setAudio] = useState<string>('');
  const [highlights, setHighlights] = useState<HighlightsEntity[]>([]);

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const selectWord = (visible: boolean) => setIsSelected(visible);

  const handleClick = () => {
    onClick?.();

    withSound &&
      withTooltip &&
      !storedWord &&
      mutate(wordWithoutSigns, {
        onSuccess: (data) => {
          const responseHighlights = !!data.highlights.length
            ? data.highlights.reduce((acc: HighlightsEntity[], highlight: SoundOutWordHighlightEntity) => {
                const keys = Object.keys(highlight);
                const highlightData = highlight[keys[0]];

                return highlightData?.timestamps
                  ? acc.concat({
                      start: highlightData.timestamps.startTime,
                      end: highlightData.timestamps.endTime,
                      indices: highlightData.letterIndices,
                    })
                  : acc;
              }, [])
            : [];

          setHighlights(responseHighlights);
          setAudio(data.audio);
          addWord({
            name: wordWithoutSigns,
            audio: data.audio,
            highlights: responseHighlights,
          });
        },
        onError: (error) => {
          console.error(error);
        },
      });
  };

  const text = (
    <Styled.Text
      as="span"
      data-text={children}
      className={clsx('tooltip-target', { isSelected, isHighlighted }, className)}
      bold={isSelected || isHighlighted}
      onClick={handleClick}
    >
      {children}
    </Styled.Text>
  );

  return withTooltip ? (
    <Tooltip
      overlay={
        <TooltipOverlay
          audio={storedWord?.audio || audio}
          highlights={storedWord?.highlights || highlights}
          letters={letters}
          isLoading={isLoading}
        />
      }
      onVisibleChange={selectWord}
      {...tooltipProps}
    >
      {text}
    </Tooltip>
  ) : (
    text
  );
};

export default memo(Word);
